import { PersistedContactGroup, SelectContactGroupsPayload } from "schemas";
import { FormTag, OnClickHandler, flexStyles, marginStyles } from "ui";
import { SelectableContactGroup } from "../SelectableContactGroup";
import { Button, Submit } from "../../ui";
import { addContactGroupMemberships } from "../../../store";
import { toast } from "react-toastify";

interface Props {
  onAddNewList: OnClickHandler;
  onDone: OnClickHandler;
  contactIds: number[];
  contactGroups: PersistedContactGroup[];
}
export function SelectGroupsForm({ onAddNewList, onDone, contactIds, contactGroups }: Props) {
  const onSubmit = async ({ contactGroups }: SelectContactGroupsPayload) => {
    if (contactGroups) {
      await Promise.all(
        contactGroups.map((contactGroupId) =>
          addContactGroupMemberships({ contactGroupId, payload: { contacts: contactIds } }),
        ),
      );
      toast.success(`${contactIds.length} contacts added to ${contactGroups?.length} groups`);
    }
    onDone();
  };

  return (
    <FormTag<SelectContactGroupsPayload> onSubmit={onSubmit} className={flexStyles.gap050}>
      {contactGroups.map((contactGroup) => (
        <SelectableContactGroup
          key={`selectable-group-${contactGroup.id}`}
          name="contactGroups"
          contactGroup={contactGroup}
        />
      ))}
      <Button className={marginStyles.mt100} buttonType="secondary" onClick={onAddNewList}>
        Add a new list
      </Button>
      <Submit label="Done" />
    </FormTag>
  );
}
