import {
  Help,
  HoldButton,
  Loader,
  Progress,
  SimpleError,
  SmallTextAccent,
  SmallTextMain,
  Span,
  Strong,
  TextMain,
  flexStyles,
  loaderStyles,
  marginStyles,
  paddingStyles,
  textStyles,
} from "ui";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { useState } from "react";
import classNames from "classnames";
import { Button, IconLink, Link, Overlay, SuperCard } from "../ui";
import { ExceededLimit } from "../contacts/ExceededLimit";
import {
  sendCampaign,
  sendTestCampaign,
  useCampaign,
  useContactGroup,
  useCurrentBusiness,
  useOverContactsLimit,
} from "../../store";

function ContactGroup({ id }: { id: number }) {
  const contactGroup = useContactGroup(id);
  if (!contactGroup) {
    return null;
  }

  return <TextMain>- {contactGroup.name}</TextMain>;
}

export function Summary() {
  const navigate = useNavigate();
  const { campaign } = useCampaign();
  const business = useCurrentBusiness();
  const [sendDisabled, setSendDisabled] = useState(false);
  const overLimit = useOverContactsLimit(0);

  if (!campaign) {
    return <Loader />;
  }

  const onSend = async () => {
    setSendDisabled(true);
    try {
      await sendCampaign(campaign.id);
      navigate("../sent", { relative: "path" });
    } finally {
      setSendDisabled(false);
    }
  };

  const contentErrors: { message: string; link: string }[] = [];
  if (!campaign.subject) {
    contentErrors.push({ message: "You haven't set a subject for this campaign", link: "../subject" });
  }
  if (!business.address) {
    contentErrors.push({
      message: "Business address required to send a campaign",
      link: "../content/address",
    });
  }

  const campaignUnreasonable = campaign.recipients.resolvedContactIds.length === 0 || contentErrors.length > 0;

  return (
    <Overlay
      className={classNames(paddingStyles.p125, flexStyles.gap125)}
      backTo={{ to: "../subject", label: "Campaign Subject" }}
      title="Summary"
      subtitle={<TextMain>Lastly check your campaign.</TextMain>}
      header="Create a campaign"
      subHeaderElement={<Progress current={5} total={5} />}
    >
      <SuperCard
        title={<SmallTextAccent>Your sender details</SmallTextAccent>}
        right={<IconLink iconType="Edit" to="sender" />}
      >
        <TextMain>
          {business.emailSettings.resolvedFromName}{" "}
          <Span className={textStyles.neutral400}>&lt;{business.emailSettings.resolvedFromEmail}&gt;</Span>
        </TextMain>
        <SmallTextMain>
          <Span className={textStyles.neutral400}>Reply-to address: </Span>
          {business.emailSettings.resolvedReplyToEmail}
        </SmallTextMain>
        {!!business.emailSettings.fromEmail || (
          <SmallTextMain className={marginStyles.mt100}>
            Want to send from your domain?
            <br />
            <Link
              className={textStyles.sizeInherit}
              href="mailto:support@litesite.com?subject=I want to send from a custom domain"
            >
              Contact support@litesite.com
            </Link>
          </SmallTextMain>
        )}
      </SuperCard>
      <SuperCard
        title={<SmallTextAccent>Campaign Basics</SmallTextAccent>}
        right={<IconLink iconType="Edit" to="../subject" />}
      >
        <TextMain>Subject:</TextMain>
        <TextMain dir="auto">
          <Strong>{campaign.subject}</Strong>
        </TextMain>
      </SuperCard>
      <SuperCard
        title={<SmallTextAccent>Content</SmallTextAccent>}
        right={<IconLink iconType="Edit" to="../content" />}
      >
        {contentErrors.length > 0 ? (
          contentErrors.map((error) => (
            <>
              <SimpleError className={textStyles.size100} key={error.message}>
                {error.message} <Link to={error.link}>Fix here</Link>
              </SimpleError>
            </>
          ))
        ) : (
          <>
            <SmallTextMain>{campaign.subject}</SmallTextMain>
            <SmallTextMain className={textStyles.neutral400}>
              Last edited: {DateTime.fromISO(campaign.updatedAt!).toRelative()}
            </SmallTextMain>
          </>
        )}
      </SuperCard>
      <SuperCard
        title={
          <>
            <SmallTextAccent>Recipients</SmallTextAccent>
            <Help>
              <SmallTextAccent>Not seeing everyone you expect?</SmallTextAccent>
              <SmallTextMain>
                We only count subscribers who confirmed their email as recipients. If you need to import a list{" "}
                <a href="mailto:support@litesite.com">contact us.</a>
              </SmallTextMain>
            </Help>
          </>
        }
        right={<IconLink iconType="Edit" to="../recipients" />}
      >
        <div className={flexStyles.vert125}>
          {campaign.recipients.resolvedContactIds.length === 0 ? (
            <SimpleError className={textStyles.size100}>
              You have not selected any recepients for this campaign <Link to={"../recipients"}>Fix here</Link>
            </SimpleError>
          ) : (
            <TextMain>
              {campaign.recipients.resolvedContactIds.length} recipient
              {campaign.recipients.resolvedContactIds.length > 1 && "s"} in total
            </TextMain>
          )}
          {campaign.recipients.contactGroupIds.length > 0 && (
            <div className={flexStyles.vert050}>
              <SmallTextMain className={textStyles.neutral500}>Selected Groups:</SmallTextMain>
              {campaign.recipients.contactGroupIds.map((id) => (
                <ContactGroup key={id} id={Number(id)} />
              ))}
            </div>
          )}
        </div>
      </SuperCard>
      {overLimit && <ExceededLimit type="campaign" />}
      <HoldButton
        disabled={overLimit || sendDisabled || campaignUnreasonable || campaign.status === "Sent"}
        onPressed={onSend}
        pressedContent={
          <>
            <Loader className={classNames(loaderStyles.size150)} fitted />
            Sending
          </>
        }
      >
        Press and hold to send
      </HoldButton>
      <Button buttonType="secondary" onClick={() => sendTestCampaign(campaign.id)}>
        Send a test email
      </Button>
    </Overlay>
  );
}
