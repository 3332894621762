import {
  Card,
  HeaderMain,
  Loader,
  SmallTextMain,
  SubheaderMain,
  TextMain,
  flexStyles,
  paddingStyles,
  textStyles,
} from "ui";
import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import classNames from "classnames";
import { Link, Overlay, SuperCard } from "../ui";
import { CampaignStat } from "./CampaignStat";
import { fetchCampaignStats, useCampaign } from "../../store";
import { HobBatchStats } from "schemas";

function percentage(number: number) {
  return `${Math.round(number * 100)}%`;
}

export function Stats() {
  const [stats, setStats] = useState<HobBatchStats | undefined>(undefined);
  const { campaign } = useCampaign();

  useEffect(() => {
    if (campaign && !stats) {
      fetchCampaignStats(campaign.id).then((stats) => setStats(stats));
    }
  }, [campaign, stats]);

  if (!campaign || !stats) {
    return <Loader />;
  }

  return (
    <Overlay
      className={classNames(paddingStyles.p125, flexStyles.gap200)}
      header="Campaign Stats"
      title={campaign.name}
      subtitle={
        <>
          <TextMain>{campaign.subject}</TextMain>
          {campaign.sentAt && (
            <SmallTextMain className={textStyles.neutral400}>
              Sent: {DateTime.fromISO(campaign.sentAt).toRelative()}
            </SmallTextMain>
          )}
          <Link href={campaign.url} target="_blank">
            Preview
          </Link>
        </>
      }
      backTo={{ to: "../..", label: "Campaigns List" }}
    >
      <div className={flexStyles.vert050}>
        <SmallTextMain>Performance</SmallTextMain>
        <div className={flexStyles.horiz050}>
          <Card className={classNames(paddingStyles.p125, flexStyles.grow)}>
            <TextMain>Open Rate</TextMain>
            <HeaderMain>{percentage(stats.opened_count / stats.total_count)}</HeaderMain>
          </Card>
          <Card className={classNames(paddingStyles.p125, flexStyles.grow)}>
            <TextMain>Click Rate</TextMain>
            <HeaderMain>{percentage(stats.opened_count / stats.total_count)}</HeaderMain>
          </Card>
        </div>
      </div>
      <div className={flexStyles.vert050}>
        <SmallTextMain>General Stats</SmallTextMain>
        <CampaignStat label="Sent" count={stats.processed_count} />
        <CampaignStat label="Opened" count={stats.opened_count} />
        <CampaignStat label="Clicked" count={stats.clicked_count} />
        <CampaignStat label="Spam" count={stats.spam_count} />
      </div>
      <div className={flexStyles.vert050}>
        <SmallTextMain>Delivery Stats</SmallTextMain>
        <SuperCard
          title={<TextMain>Successful deliveries</TextMain>}
          right={<SubheaderMain>{stats.delivered_count}</SubheaderMain>}
        />
        <SuperCard title={<TextMain>Bounces</TextMain>} right={<SubheaderMain>{stats.bounced_count}</SubheaderMain>} />
      </div>
    </Overlay>
  );
}
