import { Asserts, array, number, object, string } from "yup";
import { ContactImportStatus, ContactImportType, ContactImportTypeValues } from "../enums";
import { PersistedUserUpload } from "./UserUpload";

export const ContactImportSchema = object({
  type: string().required().oneOf(ContactImportTypeValues),
  destinationContactGroupIds: array().of(number().defined()),
}).noUnknown();
export type ContactImportPayload = Asserts<typeof ContactImportSchema>;

export type PersistedContactImport = {
  id: number;
  candidateCount: number;
  duplicateCount: number;
  destinationContactGroupIds: number[];
  type: ContactImportType;
  status: ContactImportStatus;
  userUpload?: PersistedUserUpload;
};
