import {
  EmptyElements,
  FormProvider,
  GetYourLiteSite,
  Loader,
  Progress,
  ThemedWrapper,
  flexStyles,
  marginStyles,
  opacityStyles,
  paddingStyles,
  widthStyles,
} from "ui";
import { Outlet, useNavigate } from "react-router-dom";
import { CampaignSchema } from "schemas";
import classNames from "classnames";
import { Button, Link, Overlay } from "../ui";
import { EditBusinessName, EditCampaignLogo } from "../ui/InlineEditors";
import { sendTestCampaign, useCampaign, useCurrentBusiness, useTheme } from "../../store";
import { BlocksEditor } from "../blocks";
import { useCampaignElements } from "../../store/campaigns/useCampaignElements";
import { BusinessAddressPreview } from "./BusinessAddressPreview";

export function Content() {
  const navigate = useNavigate();
  const business = useCurrentBusiness();
  const { campaign } = useCampaign();
  const elements = useCampaignElements(campaign?.id);
  const theme = useTheme(campaign?.themeId);

  if (!campaign || !theme) {
    return <Loader />;
  }

  const empty = elements?.length === 0 && (
    <EmptyElements addElement={() => navigate("blocks/new/1", { relative: "path" })} />
  );

  return (
    <FormProvider defaultValues={campaign} schema={CampaignSchema}>
      <Outlet />
      <Overlay
        className={classNames(flexStyles.grow)}
        backTo={{ to: "../design", label: "Design" }}
        title="Create a campaign"
        header="Content"
        subHeaderElement={<Progress current={2} total={5} />}
        full
      >
        <ThemedWrapper className={classNames(flexStyles.grow)} theme={theme}>
          <div
            className={classNames(
              flexStyles.vert100,
              paddingStyles.p125,
              paddingStyles.py200,
              widthStyles.full,
              widthStyles.maxWidth4225,
              marginStyles.centered,
            )}
          >
            <Link className={flexStyles.alignSelfCenter} href={campaign.url} target="_blank">
              View this email in your browser
            </Link>
            <EditCampaignLogo />
            <EditBusinessName hasLabel className={classNames({ [opacityStyles.opacity50]: !campaign.showLogo })} />
            <BlocksEditor
              elements={elements || []}
              emptyBlock={empty}
              destination={{ type: "Campaign", entity: campaign }}
            />
            <BusinessAddressPreview business={business} />
            <GetYourLiteSite business={business} />
            <div
              className={classNames(
                flexStyles.vert100,
                paddingStyles.px125,
                widthStyles.full,
                widthStyles.maxWidth4225,
                marginStyles.centered,
              )}
            >
              <Button to="../recipients">Continue</Button>
              <Button buttonType="secondary" onClick={() => sendTestCampaign(campaign.id)}>
                Send a test email
              </Button>
            </div>
          </div>
        </ThemedWrapper>
      </Overlay>
    </FormProvider>
  );
}
