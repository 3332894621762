import { Card, Icon, Loader, SmallTextMain, TextMain, flexStyles, paddingStyles, textStyles } from "ui";
import { Outlet, useNavigate } from "react-router-dom";
import { CampaignStatus, EmptyCampaign } from "schemas";
import { Switcher } from "./Switcher";
import { Button, HomeWrapper } from "../ui";
import { CampaignCard } from "./CampaignCard";
import classNames from "classnames";
import { addCampaign, useCampaigns } from "../../store";
import { DateTime } from "luxon";

interface Props {
  filter: CampaignStatus | "All";
}
export function Home({ filter }: Props) {
  const navigate = useNavigate();
  const { loading, campaigns } = useCampaigns();

  if (loading && campaigns.length === 0) {
    return <Loader />;
  }

  const localCampaigns = campaigns.filter((campaign) => campaign.status === filter || filter === "All");

  const onNew = async () => {
    const now = DateTime.now();
    const campaign = await addCampaign({
      ...EmptyCampaign,
      name: `Campaign ${now.toLocal().toLocaleString(DateTime.DATETIME_SHORT)}`,
    });
    navigate(`/campaigns/${campaign.id}/design`);
  };

  return (
    <HomeWrapper>
      <Outlet />
      <Switcher label={filter} extra={<SmallTextMain>{localCampaigns.length} campaigns</SmallTextMain>} />
      {localCampaigns.length === 0 ? (
        <Card
          className={classNames(flexStyles.vert125, flexStyles.alignStretch, paddingStyles.p125, textStyles.primary)}
        >
          <TextMain className={textStyles.center}>
            Get started with LiteSite Email Marketing!
            <br />
            Explore our simple and powerful creator
          </TextMain>
          <Button onClick={onNew}>CREATE YOUR FIRST CAMPAIGN</Button>
        </Card>
      ) : (
        <>
          <div className={classNames(flexStyles.horiz100, flexStyles.justifyEnd)}>
            <Button to="/campaigns/generate" buttonType="secondary">
              <Icon iconType="MagicWand" />
              Generate
            </Button>
            <Button onClick={onNew}>
              <Icon iconType="Plus" />
              New
            </Button>
          </div>
          {localCampaigns.map((campaign) => (
            <CampaignCard key={campaign.id} campaign={campaign} />
          ))}
        </>
      )}
    </HomeWrapper>
  );
}
